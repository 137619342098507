.h-container{
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    z-index: 10;
    padding: 30px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: background-color 300ms ease;
    -ms-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
}

.h-container a, .h-container a:hover {
    text-decoration: none;
}

.h-landing{
    background-color: transparent;
}

.h-DarkColorHeader{
    background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 767px){
    .h-container{
        height: 40px;
    }
}

@media screen and (max-width: 1024px){
    .h-container{
        height: 50px;
    }
}
