.linkHome a{
    font-family: 'Bangers', serif;
    text-align: left;
    font-size: 1.6rem;
    color: var(--colorV);
    margin-left: 4vw;
}

.linkHome a:hover{
    transform: scale(1.5);
    color: var(--colorI);
}

.footer-link a{
    font-size: 1.3rem;
}

.linkHome a,.linkHome a:hover, .footer-link a{
    text-decoration: none;
}

@media screen and (max-width: 767px){

    .linkHome a{
    font-size: 1.4rem;

    }

    .footer-link a {
        font-size: 1.1rem;
        margin: 0px;
    }
}