.lan-container{
    padding: 5vw;
    height: 100vh;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    background-image: url('../../Media/photos/back1-landing2-desktop.jpg');
    background-position:  right;
    box-sizing: border-box;
    position: relative;
}

.lan-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.35);
  }

.lan-common{
    position: absolute;
    top: 40vh;
    transform: translate(-50%, -50%);
}

.lan-avatar{
    width: 20vw;
    height: 20vw;
    border: 1px solid black;
    border-radius: 50%;
    background-image: url('../../Media/photos/personal-avatar.webp');
    background-position: center;
    box-shadow: inset 0 0 2vw rgba(0, 0, 0, 1.0); 
}

.lan-msg{
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 3rem;
    font-family: 'Bangers', 'Open sans', serif;
    font-weight: 300;
    color: var(--colorV);
    left: 50vw;
    z-index: 2;
}

.lan-container, .lan-msg{
    display: flex;
}

.lan-container, .lan-avatar{
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width: 767px){
    .lan-msg{
        font-size: 1.5rem;
    }

    .lan-container{
        background-image: url('../../Media/photos/back1-landing2-device.jpg');
    }

}