@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');

:root{
  --colorI: #ff7f2f;
  --colorII: #3d4249;
  --colorIII: #cecece;
  --colorIV: #b6b6b6;
  --colorV:#f5f5f5;
  --colorVI: #282828 ;
  --colorVII: #f0f0f0;
}

html, body, #root{
  min-height: 100%;
  min-width: 100%;
  margin: 0px;
  padding: 0px; 
  display:block;
  font-family: 'Open Sans', serif;
 }

.App {
  text-align: center;
  box-sizing: border-box;
  position: relative;
  background-color: var(--colorVII);
  margin: 0px;
  min-height: 100vh;
  min-width: 100%;
  position: relative;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.App h1, h2, h3, h4, h5 {
  font-family: 'Bangers' , 'Open Sans', serif;
}