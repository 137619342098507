.contact-container{
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    animation: showDiv 500ms ease;
}

.contact-container h2{
    margin-bottom: 30px;
}

.contact-container p{
    text-align: center;
}

.lazy-common-contact{
    min-height: 40vh;
}