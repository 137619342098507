.h-link{
    font-family: 'Bangers', serif;
    padding: 15px;
}

.h-link > a  {
    padding: 5px;
    margin: 5px;
    color: var(--colorV);
    align-self: flex-end;
}

.h-link > a:hover {
    color: var(--colorI);
    cursor: pointer;
}

.h-link, .h-link > a,.h-link > a:hover{
    text-decoration: none;
} 

@media screen and (max-width: 767px){

    .h-link{
        font-size: 1rem;
        align-self: center;
    }

}