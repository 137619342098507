.h-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    animation: showMenuHam 500ms ease;
}

#btnHam {
    background-image: url('../../Media/icons/menuHam3.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
}

#btnHam:hover {
    cursor: pointer;
    background-image: url('../../Media/icons/menuHam3Active.png');
    animation: scaleIconActive 500ms ease;
}

@media screen and (max-width: 767px){
    .h-links{
        width: 100%;
        margin: 0px;
        font-size: 1.5rem;
        position: absolute;
        top: 60px;
        right: 0px;
        z-index: 9;
        background-color: var(--colorVI);
    }
}

@keyframes showMenuHam {
        from {left: 100%;}
        to {left: 0%;}
}

@keyframes scaleIconActive {
    from {
        transform: scale(1.4);
    }

    to {
        transform: scale(1);
    }
}