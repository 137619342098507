.root-container{
    position: relative;
    margin: 0px;
}

.root-section{
    scroll-margin-top: 60px;
}

.section-common{
    padding: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
}


@media screen and (max-width: 767px){
    .section-common{
        padding: 40px;
    }
}

@media screen and (min-width: 1024px){
    .section-common{
        padding-left: 250px;
        padding-right: 250px;
    }
}