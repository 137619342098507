.f-container {
    min-height: 15vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: var(--colorVII);
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
}

#f-credit {
    margin-left: 20px;
    color: var(--colorVII);
    text-decoration: none;
}

#f-credit:hover{
    cursor: pointer;
    color: var(--colorI);
}

.f-nameDescription {
    font-size: small;
    justify-content: space-evenly;
    align-self: center;
}

.f-container,.f-links,.f-nameDescription  {
    display: flex;
}

.f-container,.f-links{
    justify-content: center;
    align-items: center;
}

.f-links, .f-nameDescription { 
    z-index: 4;
}

@media screen and (max-width: 767px) {

    .f-links {
        align-items: center;
    }
    .f-nameDescription {
        margin: 10px;
    }

    .f-links, .f-nameDescription  {
        flex-direction: column;
    }
}
