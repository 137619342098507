.ab-container{
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    animation: showDiv 500ms ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ab-avatar{
    width: 35vw;
    height: 35vw;
    align-self: center;
    border: 1px solid var(--colorVI);
    border-radius: 100%;
    background-image: url('../../Media/photos/personal-avatar2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0px 0px 10px 5px var(--colorII) ;
}

.ab-common{
    margin-bottom: 30px;
}

.ab-container p{
    text-align: left;
}

.lazy-common{
    min-height: 50vh;
}

@media screen and (orientation: portrait){
    .ab-avatar{
        width: 40vw;
        height: 40vw;
    }
}

@media screen and (orientation: landscape){
    .ab-avatar{
        width: 40vh;
        height: 40vh;
    }
}

@media screen and (min-width: 1025px){
    .ab-avatar{
        width: 30vh;
        height: 30vh;
    }
}

@keyframes showDiv {
     0%{    
         opacity: 0;
    }
  
    50%{    
        opacity: 0.6;
    }

    100%{    
        opacity: 1;
    }

}