.p-container {
    z-index: 1;
    position: relative;
    flex-direction: column;
    justify-content: center;
    animation: showDiv 500ms ease;
}

.p-container h2 {
    margin-bottom: 40px;
}

.p-proyects {
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.p-container,.p-proyects{
    display: flex;
    align-items: center;
}

.lazy-common{
    min-height: 50vh;
}

@media screen and (max-width: 550px)  {
    .p-proyects {
        justify-content: center;
    }
}